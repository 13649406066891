import React, { useState } from "react";
import { ConnectBtn, MainSection } from "./elements";
import { MainContainer, MainModel } from "components/common";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Image } from "react-bootstrap";
import { greenpaper, greenpaper2, logo } from "assets";
import { mainModel } from "store/redux/slices/helperSlices/modelSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const NavbarComp = () => {
  const dispatch = useAppDispatch();

  const [connectModel, setConnectModel] = useState(false);

  const { account } = useAppSelector((state) => state.web3Connect);

  // const {convertedPrice} = PriceConvertorHook({ amount: 1, id: "2" })

  const handleModelOpen = () => {
    setConnectModel(true);
    dispatch(mainModel(true));
  };

  return (
    <MainSection>
      <MainModel connectModel={connectModel} />
      <Navbar expand="lg">
        <MainContainer>
          <Navbar.Brand href="#home">
            <Image src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <Nav.Link href="/">Page d’Accueil</Nav.Link>
              <Nav.Link href="#about">Comment Investir ?</Nav.Link>
              <Nav.Link href="#roadmap">Feuille De Route</Nav.Link>
              <Nav.Link href="#token">Tokénomique</Nav.Link>
              <Nav.Link
                onClick={() => window.open(greenpaper2, "_blank", "noreferrer")}
              >
                Livre Vert FR
              </Nav.Link>
              <Nav.Link
                onClick={() => window.open(greenpaper, "_blank", "noreferrer")}
              >
                Livre Vert EN
              </Nav.Link>
              <Nav.Link href="#iso">Isométrique</Nav.Link>
            </Nav>
            {account ? (
              <ConnectBtn>connecté</ConnectBtn>
            ) : (
              <ConnectBtn onClick={handleModelOpen}>
                Connecter le portefeuille
              </ConnectBtn>
            )}
          </Navbar.Collapse>
        </MainContainer>
      </Navbar>
    </MainSection>
  );
};

export default NavbarComp;
