import { video } from "assets";
import { MainContainer } from "components/common";
import React from "react";
import { MainSection, VideoWrapper } from "./elements";

const VideoSection = () => {
  return (
    <MainSection>
      <MainContainer>
        <VideoWrapper>
          <video src={video} loop controls></video>
        </VideoWrapper>
      </MainContainer>
    </MainSection>
  );
};

export default VideoSection;
