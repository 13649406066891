import { helpimg } from "assets";
import styled from "styled-components";

export const MainSection = styled.section`
  .container {
    padding-bottom: 4rem;
  }
  .maindiv
  {
    position: relative;
  }
 .seconddiv
 {
  position: absolute;
  top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
 }


`;



export const Text = styled.p`
  color: #5ea624;
  text-align: center;
  font-family: "Zen Dots";
  font-size: 29.459px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 29.459px */
  text-transform: capitalize;

  @media (max-width: 756px)
  {
    font-size: 16px;
  }
`;

export const ContactBtn = styled.button`
  color: #fff;
  text-align: center;
  font-family: "Zen Dots";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 31.423px;
  background: #5ea624;
  width: 307px;
  height: 48px;
  border: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;

  @media (max-width: 756px)
  {
    font-size: 12px;
    width: 259px;
    height: 32px;
    margin-top: 0rem;
    margin-bottom: .3rem;
  }
`;
