import styled from "styled-components";

export const MainModel = styled.div``;

export const ModelsData = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 459px;
  min-height: 340px;
  border-radius: 10px;
  border: 1px solid #5ea624;
  background: rgba(11, 31, 6, 0.39);

  /* display: flex;
  justify-content: center;
  align-items: center; */
  @media (max-width: 420px) {
    width: 320px;
    height: 300px;
  }

  @media (max-width: 380px) {
    width: 230px;
    height: 300px;
  }

  .cross-icon {
    text-align: end;
    padding-top: 0.7rem;
    padding-right: 0.5rem;
  }
  .main-dev {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 4rem;
  }
`;

export const ConnectButton = styled.button`
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  border-radius: 30px;
  width: 360px;
  height: 110px;

  background: ${(p) =>
    p.theme.model.connectModelbtnBgColor &&
    p.theme.model.connectModelbtnBgColor};
  color: ${(p) =>
    p.theme.model.connectModelbtnTextColor &&
    p.theme.model.connectModelbtnTextColor};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  border: none;
  font-weight: 700;
  font-size: 19px;
  @media (max-width: 415px) {
    width: 300px;
  }
`;

export const ConnectIcon = styled.img`
  width: 60px;
  margin-right: 1rem;
`;

export const MainWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #5ea624;
  width: 176px;
  height: 149px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  h5 {
    color: #fff;
    text-align: center;
    font-family: "Zen Dots";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 35.387px; /* 168.508% */
    margin-top: 0.5rem;
  }

  img {
    width: 67px;
    height: 61px;
    object-fit: contain;
    flex-shrink: 0;
  }
`;
