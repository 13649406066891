import styled from "styled-components";

export const MainSection = styled.section`
  .navbar {
    border-bottom: 0.982px solid #5ea624;
    background: rgba(43, 43, 43, 0.05);
    backdrop-filter: blur(7.364655494689941px);
    height: 79px;

    @media (max-width: 990px) {
      height: 100%;
    }
  }

  .navbar-nav .nav-link.active,
  .navbar-nav .nav-link.show {
    color: #5ea624 !important;
  }

  .nav-link {
    color: #fff;
    font-family: "Zen Dots";
    font-size: 11.783px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;

    &:hover {
      color: #5ea624;
    }
    @media (max-width: 1400px) {
      font-size: 10px;
    }

    /* &::after {
      content: "";
      display: block;
      width: 0%;
      height: 2px;
      background: #5ea624;
      transition: width 0.3s ease;
    }
    &:hover::after {
      width: 100%;
    } */

    &:not(:first-child) {
      margin-left: 0.5rem;
      @media (max-width: 1400px) {
        margin-left: 0.1rem;
      }

      @media (max-width: 990px) {
        margin-left: 0rem;
      }
    }

    @media (max-width: 990px) {
      text-align: center;
    }
  }

  .navbar-toggler {
    background-color: #677e2c;
  }
`;

export const ConnectBtn = styled.button`
  color: #fff;
  text-align: center;
  font-family: "Zen Dots";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 31.423px;
  background: #5ea624;
  border: none;
  /* width: 140px;
  height: 34px; */
  padding: 15px 13px;
  transition: 0.3s ease-in;
  &:hover {
    background: #7dcd3d;
    animation-name: heartBeat;
    animation-duration: 1s;
  }
  @media (max-width: 1200px) {
    padding: 10px 12px;
    font-size: 9px;
  }

  @media (max-width: 990px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;
