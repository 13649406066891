import { tokenimg, tree4 } from "assets";
import { MainCol, MainContainer, MainRow } from "components/common";
import React from "react";
import { Image } from "react-bootstrap";

import {
  AirdropText,
  AirDropTitle,
  ImgDiv,
  ImgWrapper,
  MainSection,
  Text,
  Title,
  TopTitle,
} from "./elements";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
const TokenAirdropSection = () => {
  return (
    <MainSection>
      <MainContainer>
        <AnimationOnScroll animateIn="animate__flipInX">
          <Title>
            ARGANACOIN
            <br />
            <p>Investir Dans ARG</p>
          </Title>
        </AnimationOnScroll>
        <ImgDiv>
          <Image src={tree4} />
        </ImgDiv>
        <MainRow className="main-row">
          <MainCol lg={7}>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <TopTitle>JETON ARGANACOIN - ARG</TopTitle>
              <Text>
                <div style={{ marginLeft: "-1rem" }}>
                  <ul>
                    <li>
                      Un total de <span>180,000,000 ARG </span>(offre totale).
                    </li>
                    <li>
                      <span>20%</span>&nbsp;de l'offre est alloué à <span>ICO</span>
                      ,soit <span>36,000,000 ARG</span>.
                    </li>
                    <li>
                      L'ICO se déroulera sur 24 mois, avec un nombre de{" "}
                      <span>18,000,000</span> ARG par an.
                    </li>
                    <li>
                      <span>13,500,000 ARG</span>  seront alloués au prix
                      préférentiel de <span>0.25 c/€</span> pour la 1ère année.
                    </li>
                    <li>
                      <span>13,500,000 ARG</span> seront alloués au prix
                      préférentiel de <span>0.50 c/€</span> pour la 2ème année.
                     <br /> Le prix sera ajusté en fonction de l'avancement de la
                      plateforme <span>"SEED IT YOURSELF"</span> et de la valeur
                      ajoutée au projet.
                    </li>
                    <li>
                      <span>(1/4</span> de l'offre destinée à l'ICO), soit &nbsp;
                      <span>9,000,000 ARG</span> sur 24 mois, sera réservé aux{" "}
                      <span>500</span> clients/investisseurs à un prix
                      promotionnel de :
                    </li>
                    <li>
                      <span>0,20 c/€ (0.22 USDT)</span> pendant les 12 premiers
                      mois.
                    </li>
                    <li>
                      <span>0,45 c/€ (0.49 USDT)</span> au cours des 12 derniers
                      mois.
                    </li>
                  </ul>
                </div>
              </Text>
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={5}>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <ImgWrapper>
                <Image src={tokenimg} fluid />
              </ImgWrapper>
            </AnimationOnScroll>
          </MainCol>
        </MainRow>
        <AnimationOnScroll animateIn="animate__fadeInUp">
          <AirDropTitle>AIRDROP ET PROMOTION</AirDropTitle>
          <AirdropText>
            <ul>
              <li>
                <span>1%</span>&nbsp; de l'offre totale, soit{" "}
                <span>1,800,000 ARG</span>, sera alloué à l’ICO sur 24 mois pour
                récompenser les investisseurs pour leur participation au projet,
                leur soutien sur les réseaux sociaux, et la confiance qu’ils
                accordent au Projet.
              </li>
              <li>
                Les jetons <span>ARG</span>&nbsp;seront utilisés pour les campagnes de
                marketing, les partenariats et les initiatives promotionnelles
                visant à accroître la visibilité du projet Arganacoin afin
                d’inviter des Investisseurs et clients potentiels.
              </li>
            </ul>
          </AirdropText>
        </AnimationOnScroll>
      </MainContainer>
    </MainSection>
  );
};

export default TokenAirdropSection;
