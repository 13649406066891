import styled from "styled-components";

export const MainWrapper = styled.div`
  border: 1px solid #5ea624;
  background: rgba(31, 119, 9, 0.05);
  backdrop-filter: blur(30px);
  min-height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
gap: 10em;

@media (max-width: 1300px) {
    display: flex;
    gap: 4em;
  }

  @media (max-width: 990px) {
    margin-top: 4rem;
    margin-bottom: 2rem;
    padding: .5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
  }
  h5 {
    color: #5ea624;
    text-align: center;
    font-family: "Zen Dots";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    margin-bottom: 0rem;
    word-wrap: wrap;
    @media (max-width: 1200px) {
      font-size: 12px;
    }

 

    @media (max-width: 550px) {
      font-size: 10px;
    }

    span 
    {
      color: #fff;
      font-family: "Zen Dots";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 550px) {
      font-size: 10px;
    }

    }
  }
`;
