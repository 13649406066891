import styled from "styled-components";

export const MainSection = styled.section`
  .container {
    margin-top: 6rem;
    padding-bottom: 6rem;

    @media (max-width: 767px) {
      margin-top: 4rem;
    }
  }

  .main-row {
    margin-top: 4rem;
    @media (max-width: 767px) {
      margin-top: 0rem;
    }
  }
`;

export const Title = styled.h5`
  color: rgba(61, 64, 68, 0.35);
  text-align: center;
  font-family: "Zen Dots";
  font-size: 58.917px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 58.917px */
  text-transform: capitalize;

  @media (max-width: 767px) {
    font-size: 40px;
  }

  p {
    color: #5ea624;
    text-align: center;
    font-family: "Zen Dots";
    font-size: 29.459px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 29.459px */
    text-transform: capitalize;
    transform: translateY(-1rem);

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  &.title2 {
    margin-top: -1.5rem;

    @media (max-width: 990px) {
      margin-top: 5rem;
    }
  }
`;

export const ImgDiv = styled.div`
  margin-top: -14rem;
  margin-left: 1rem;

  @media (max-width: 990px) {
    display: none;
  }
`;

export const TopTitle = styled.h5`
  color: #ece8f0;
  font-family: "Zen Dots";
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 109.091% */
  margin-top: 2rem;
  @media (max-width: 767px) {
    font-size: 21px;
  }
`;

export const Text = styled.div`
  margin-top: 1.5rem;
  ul li {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.5px; /* 150% */
  }
  ul li span {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.5px; /* 150% */
  }
`;

export const ImgWrapper = styled.div`
  text-align: center;
  /* margin-top: 1.3rem; */
  @media (max-width: 767px) {
    margin-top: 2rem;
  }
`;

export const AirDropTitle = styled.h5`
  color: #ece8f0;
  font-family: "Zen Dots";
  text-align: center;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 109.091% */
  margin-top: 3.5rem;
  @media (max-width: 767px) {
    font-size: 22px;
  }
`;

export const AirdropText = styled.div`
  margin-top: 1.5rem;
  ul li {
    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.5px;
    text-align: center;
    margin-top: 1rem;
    @media (max-width: 767px) {
      text-align: start;
    }
  }

  ul li span {
    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.5px;
    text-align: center;
  }
`;
