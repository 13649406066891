export const data = [
  {
    id: 1,
    title: `2022`,
    typeData: [
      {
        value: "Constitution du Livre Vert \n & Ecosystème ARGANACOIN ",
      },

      {
        value: "Prospection de Partenariat \n (Morocco) ",
      },
      {
        value: "Prospection de Partenariat \n (Blockchain)",
      },
    ],
    text: `Constitution du Livre Vert \n & Ecosystème ARGANACOIN \n Prospection de Partenariat \n (Morocco) \n Prospection de Partenariat \n (Blockchain)`,
  },
  {
    id: 1,
    title: `2023`,
    typeData: [
      {
        value: "Création du Contrat intelligent jeton\n   ARGANACOIN - ARG ",
      },

      {
        value: "Marketing & Communication\n  Promotion du jeton ARGANACOIN",
      },
      {
        value: "Vente privée du jeton\n  ARGANACOIN (spécial ICO)",
      },
      {
        value: "Finalisation des Partenariats (Maroc)",
      },
      {
        value: "Réflexion et architecture de la\n  Plateforme digitale\n  « SEED IT YOURSELF » Parrainage\n  d’Arganiers via des NFTs",
      },
    ],
    text: `Création du Contrat intelligent jeton\n   ARGANACOIN - ARG \n Marketing & Communication\n  Promotion du\n  jeton ARGANACOIN \n Vente privée du jeton\n  ARGANACOIN (spécial ICO) \n Finalisation des Partenariats (Maroc) \n Réflexion et architecture de la\n  Plateforme digitale\n  « SEED IT YOURSELF » Parrainage\n  d’Arganiers via des NFTs`,
  },
  {
    id: 1,
    title: `2024`,
    
    typeData: [
      {
        value: "Création des Contrats Intelligents \n  NFTs",
      },

      {
        value: "Création de la Plateforme\n  Marketplace NFT - ARGANAGOLD",
      },
      {
        value: "Développement des projets pour \n la protection de l’Arganier",
      },
      {
        value: " Présentation de la Plateforme\n  « SEED IT YOURSELF »",
      },
      
    ],
    text: `Création des Contrats Intelligents \n  NFTs \n Création de la Plateforme\n  Marketplace NFT - ARGANAGOLD \n Développement des projets pour \n la protection de l’Arganier \n Présentation de la Plateforme\n  « SEED IT YOURSELF » `,
  
  },
  {
    id: 1,
    title: `2025`,
    typeData: [
      {
        value: "Réflexion et architecture de la Plateforme « ARGANA KINGDOM & ARGANAZONE » portail commercial et international dédié Aux coopératives agricoles marocaines",
      },

      {
        value: "Développement réseaux et promotion de la Plateforme marchande \n  « ARGANA KINGDOM »",
      },
      {
        value: "Listing du jeton ARGANACOIN sur les\n Plateformes d ’exchanges",
      },
      
      
    ],
    text: `Réflexion et architecture de la Plateforme « ARGANA KINGDOM & ARGANAZONE » portail commercial et international dédié Aux coopératives agricoles marocaines\n Développement réseaux et promotion de la Plateforme marchande \n  « ARGANA KINGDOM »\n Listing du jeton ARGANACOIN sur les\n Plateformes d ’exchanges`,
  },
];
