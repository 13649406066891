import React from "react";
import { ImgDiv, MainSection, Title } from "./elements";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Image } from "react-bootstrap";
import { isoimg } from "assets";
const IsomatricComp = () => {
  return (
    <MainSection id="iso">
      <AnimationOnScroll animateIn="animate__flipInX">
        <Title>
          ISOMETRIQUE
          <br />
          <p>ISOMETRIQUE</p>
        </Title>
      </AnimationOnScroll>
      <ImgDiv>
        <Image src={isoimg} fluid />
      </ImgDiv>
    </MainSection>
  );
};

export default IsomatricComp;
