import styled from "styled-components";

export const MainSection = styled.section`
  border-top: 1px solid #5ea624;
  .container {
    padding-bottom: 1rem;
    margin-top: 3rem;
  }
`;

export const Text = styled.p`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
  margin-top: 1.5rem;
  margin-bottom: 0rem;

  span {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
  }

  @media (max-width: 990px) {
    text-align: center;
  }
`;

export const LinkDiv = styled.div`
  h5 {
    color: #fff;
    font-size: 15.711px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.603px; /* 137.5% */
    margin-bottom: 0rem;

    @media (max-width: 990px) {
      text-align: center;
      margin-top: 2rem;
    }
  }

  p {
    color: #fff;
    font-size: 15.711px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.603px; /* 137.5% */
    margin-bottom: 0rem;
    margin-top: 1rem;
    cursor: pointer;
    @media (max-width: 990px) {
      text-align: center;
    }
  }

  &.last-div {
    margin-left: 2.5rem;
    @media (max-width: 990px) {
      margin-left: 0rem;
    }
  }
  &.last-div1 {
    margin-left: 2rem;
    @media (max-width: 990px) {
      margin-left: 0rem;
    }
  }
`;

export const LastText = styled.p`
  color: #fff;
  font-size: 12.008px;
  font-style: normal;
  font-weight: 300;
  line-height: 24.017px; /* 200% */
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 0rem;
  margin-top: 3rem;
`;

export const ImgDiv = styled.div`
  @media (max-width: 990px) {
    text-align: center;
  }
`;
