import styled from "styled-components";

export const MainSection = styled.section`
  .container {
    margin-top: 6rem;
  }
`;

export const VideoWrapper = styled.div`
  video {
    /* width: 100%; */
    height: 704px;
    border: 6px solid #5ea624;

    @media (max-width: 1400px) {
      width: 100%;
    }
    @media (max-width: 990px) {
      height: 550px;
    }

    @media (max-width: 767px) {
      height: 210px;
    }
  }
`;
