import { tokennmicsimg, tokennmicsimg1, tree3, tree5 } from "assets";
import { MainCol, MainContainer } from "components/common";
import React from "react";
import { Image } from "react-bootstrap";
import { ImgDiv, ImgDiv2, ImgWrapper, MainSection, Title } from "./elements";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
const TokennomicsSection = () => {
  return (
    <MainSection id="token">
      <MainContainer>
        <ImgDiv>
          <Image src={tree3} />
        </ImgDiv>
        <AnimationOnScroll animateIn="animate__flipInX">
          <Title>
            Tokénomique
            <br />
            <p>Tokénomique</p>
          </Title>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp">
          <ImgWrapper>
            <Image src={tokennmicsimg1} fluid />
          </ImgWrapper>
        </AnimationOnScroll>
        <ImgDiv2>
          <Image src={tree5} />
        </ImgDiv2>
      </MainContainer>
    </MainSection>
  );
};

export default TokennomicsSection;
